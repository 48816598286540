.pwu .top-content > .container {
	justify-content: flex-start;
}

.pwu .top-content small {
	color: #fff;
	font-size: 14px;
	text-transform: capitalize;
}

.pwu nav + div > div:nth-child(4) {
	margin: 5px 0 8px;
}

.pwu nav + div > div:last-child {
	margin-top: 10px;
}

.pwu #btns-container {
	margin: 20px 0;
	width: 100%;
}

.pwu #btns-container > a {
	margin-bottom: 5px;
}

.pwu #btns-container a {
	width: 40%;
	padding-left: 0;
	padding-right: 0;
}

.pwu #btns-container button {
	width: 100%;
}

.pwu #btns-container > div {
	display: flex;
	gap: 5px;
	margin-bottom: 5px;
}

.pwu #req-docs > div > div {
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 8px 0;
	text-align: left;
}

.pwu #gs {
	background: var(--neon-light);
}

.pwu #gs .container {
	background: #fff;
	padding: 35px 10px;
	border-radius: 15px;
	text-align: center;
	translate: 0 -20%;
}

.pwu #gs .font-s {
	margin: 5px 0 35px;
}

.pwu #why-we .container {
	padding: 45px 10px;
}

.pwu #why-we h2,
.pwu #why-we p {
	text-align: center;
}

.pwu #why-we p {
	width: 70%;
	margin: 20px auto 30px;
}

.pwu #why-we ul {
	width: fit-content;
	margin: 0px auto;
	li {
		margin: 10px 0;
	}
}

.pwu .mcard {
	border-radius: 15px;
	background: #fff;
	box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.12);
	padding: 15px 20px;
}

.pwu .mcard .font-l {
	font-weight: 600;
}

.p-step {
	flex-direction: column;
	text-align: center;
	padding: 35px 20px 25px;
	width: 280px;
	border-radius: 20px;
	gap: 30px;
	background: #fff;
	box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.12);
}

.p-step .font-md {
	margin: 10px 0;
	font-weight: 600;
}

.p-step img {
	width: 80px;
	height: 80px;
}

.p-step > div:first-child {
	width: fit-content;
	height: fit-content;
	border-radius: 50%;
	border: 1px solid var(--neon);
	padding: 1.75rem;
	display: flex;
}

.p-step h2 {
	color: #333;
	font-weight: 700;
}

#hiw {
	background: url("/public/images/hiw-bg.jpg");
	background-repeat: no-repeat;
}

#hiw .container > h2 {
	padding: 60px 0 80px;
	text-align: center;
}

#on-start .container {
	padding-top: 100px;
}

#on-start > div > .font-s {
	margin: 15px 0;
}

#on-start #steps-wrapper {
	margin: 50px 0 0;
}

#on-start #steps-wrapper .step {
	display: flex;
	gap: 15px;
	margin-bottom: 40px;
	width: 70%;
}

#on-start #steps-wrapper > div > .step:last-child {
	margin-bottom: 0;
}

#on-start #steps-wrapper .step > div:first-child {
	border-radius: 50%;
	border: 1px solid black;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	aspect-ratio: 1;
	font-weight: 600;
	opacity: 0.6;
	margin-top: 5px;
}

.pwu #start-now {
	margin: 50px auto;
}
.pwu #start-now > .container {
	background: url("/public/images/start-journey.jpg");
	background-repeat: no-repeat;
	border-radius: 15px;
}
.pwu #start-now > .container > div {
	padding: 30px 60px;
}
.pwu #start-now * {
	color: #fff;
}
.pwu #start-now .font-s {
	margin: 15px 0;
	opacity: 1;
}

#faq {
	margin: 80px 0;
}

#faq > div > h2 {
	margin-bottom: 40px;
}

.faq {
	border-bottom: 1px solid var(--border);
	padding-right: 30px;
}

.faq > div:first-child {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 30px 0;
	opacity: 0.85;
	gap: 20px;
}

.faq > div:nth-child(2) {
	padding-bottom: 30px;
	display: block;
	width: 90%;
}

@media screen and (max-width: 1024px) and (min-width: 700px) {
	.pwu .mcard {
		padding: 1.5vw 2vw;
	}
	.pwu .mcard img {
		width: 6vw;
	}
	#on-start #steps-wrapper img {
		height: 40vw;
	}
	#on-start #steps-wrapper .step {
		width: 90%;
	}
}

@media screen and (max-width: 700px) {
	.faq {
		padding-right: 10px;
	}
	.faq > div:nth-child(2) {
		width: 100%;
	}
	.faq > div:first-child svg {
		width: 30px;
	}
	.pwu #start-now > .container > div {
		padding: 15px 20px;
	}
	.pwu #start-now .font-s {
		margin: 10px 0 30px;
	}
	#steps-wrapper img {
		display: none;
	}
	#on-start #steps-wrapper .step {
		width: unset;
	}
	#hiw .container > h2 {
		padding: 40px 0 50px;
	}
	#p-steps-wrapper {
		flex-direction: column;
		gap: 16px;
	}
	.p-step {
		width: 265px;
		box-shadow: 0px 0px 16px 8px rgba(0, 0, 0, 0.12);
	}
	.pwu #btns-container a {
		width: 100%;
	}
	.pwu #why-we p {
		width: 90%;
	}
	.pwu .mcard img {
		display: none;
	}
	.pwu #gs .container {
		padding: 35px 0;
	}
	.pwu #why-we .container {
		padding: 35px 0;
	}
	.pwu .mcard {
		background: none;
		box-shadow: none;
		padding: 0;
	}
	.pwu .mcard .font-l {
		font-size: 16px;
	}
	.pwu .mcard .font-s {
		font-size: 12px;
		opacity: 1;
	}
	#req-docs {
		display: flex;
		flex-direction: column;
		gap: 0;
		width: fit-content;
		margin: auto;
		align-items: flex-start;
	}
	.pwu #btns-container {
		flex-direction: column;
		gap: 5px;
	}
	.pwu .top-content {
		height: 101vh;
	}
	.pwu :is(.top-content, #on-start, #start-now, #faq, #footer) {
		padding-left: 10px;
		padding-right: 10px;
	}
	.pwu #gs {
		background: transparent;
		height: fit-content;
		border-bottom: 1px solid var(--border);
	}
	.pwu #gs .container {
		height: fit-content;
		translate: unset;
		margin-top: -14%;
	}
	#on-start .container {
		padding: 80px 0 15px;
	}
}

@media screen and (max-width: 400px) {
	.pwu #btns-container > div {
		flex-wrap: wrap;
	}

	.pwu #gs .container {
		translate: 0 0 !important;
	}
}
