#business-status {
	padding: 0.25rem 0;
	background: var(--neon);
	border-radius: 6px;
	margin-bottom: 10px;
	gap: 10px;
	span,
	svg {
		opacity: 1;
		color: white;
		stroke-width: 1.5px;
	}
	&.pending {
		background: #ffc107;
	}
	&.disabled {
		background: var(--danger);
	}
}

#business_cover_images {
	--rounded: 10px;
	--gap: 10;
	--cols-count: 3;

	--gap-per-col: calc((var(--gap) * (var(--cols-count) - 1)) / var(--cols-count));
	--primary-w: calc(60% - (var(--gap-per-col) * 1px));
	--primary-h: auto;
	--center-w: calc((40% / (var(--cols-count) - 1)) - (var(--gap-per-col) * 1px));
	--portrait-w: calc((40% / (var(--cols-count) - 1)) - (var(--gap-per-col) * 1px));
	--center-img-w: 100%;
	--center-img-h: 50%;

	& > div {
		display: flex;
		gap: calc(1px * var(--gap));
		overflow: hidden;
		aspect-ratio: 3.5;
	}
	& label {
		display: block;
		height: 100%;
		width: 100%;
		border-radius: var(--rounded);
		overflow: hidden;
	}
	& img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: var(--rounded);
	}
	& > div > div {
		display: flex;
	}
	& > div > div#primary-cover {
		width: var(--primary-w);
		height: var(--primary-h);
	}
	& > div > div#center-cover {
		display: flex;
		width: var(--center-w);
		gap: calc(1px * var(--gap));
		flex-direction: column;
		& > * {
			height: calc(var(--center-img-h) - calc(1px * (var(--gap) / 2)));
			width: var(--center-img-w);
		}
	}
	& > div > div#portrait-cover {
		min-width: var(--portrait-w);
		width: var(--portrait-w);
		max-width: var(--portrait-w);
	}
}

#profile-info > div:first-child {
	margin: 20px 0 10px;
	display: flex;
	justify-content: space-between;
}

#profile-info > div:nth-child(2) > div {
	margin-bottom: 5px;
}

.profile-btns-container {
	margin-top: 15px;
	display: flex;
	gap: 8px;
}

.profile-btns-container > * {
	width: 25%;
	text-decoration: none;
}

.profile-btns-container button {
	display: flex;
	align-items: center;
	gap: 10px;
	justify-content: center;
}

.profile-btns-container > a > button {
	width: 100%;
}

.profile-btns-container svg {
	fill: var(--neon);
}

.profile-btns-container + .profile-btns-container {
	margin-top: 8px;
}

.nr-num-wrpr {
	display: inline;
}

.nr-num-wrpr * {
	margin-left: 10px;
}

#ptabs {
	display: flex;
	margin: 30px 0;
	border-bottom: 1px solid var(--gray);
}

#ptabs a {
	text-decoration: none;
}

#ptabs a > div {
	padding: 10px 20px;
	font-weight: 600;
}

#ptabs .selected {
	position: relative;
}

#ptabs .selected > span {
	color: var(--neon);
}

#ptabs .selected::after {
	content: "";
	position: absolute;
	width: 100%;
	height: 2px;
	background: var(--neon);
	border-radius: 1rem;
	bottom: 0;
	translate: 0 50%;
	left: 0;
}

#pt-content {
	margin-bottom: 80px;
	position: relative;
}

#order-online {
	display: flex;
	gap: 5vw;
}

#order-online > div:first-child {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

#order-online > div:last-child {
	width: 100%;
}

#order-online > div:first-child button {
	width: 150px;
	padding: 16px 32px;
	display: flex;
	align-items: center;
	gap: 10px;
	border-radius: 5px;
	background: var(--neon-light);
	font-weight: 600;
	text-align: left;
}

#order-online > div:first-child button.selected {
	background: var(--neon);
}

#order-online > div:first-child button.selected span {
	color: white;
}

#order-online > div:first-child button.selected svg {
	fill: white;
	stroke: white;
}

.oprod {
	border-radius: 15px;
	background: #fff;
	display: flex;
	margin: 25px 0;
	overflow: hidden;
	border: 0.1px solid #7c7c7c11;
	box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.12);
	max-width: 700px;
}

.oprod-content {
	padding: 20px 25px;
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.oprod-img {
	height: 100%;
	width: 14rem;
	aspect-ratio: 1;
	flex-shrink: 0;
	object-fit: cover;
}

.oprod-content > div:first-child {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.oprod > div:last-child {
	padding: 10px 10px 0 0;
}

.plant-edit-btn {
	display: flex;
	padding: 8px;
	border-radius: 50%;
	transition: all 500ms ease;
	border: 1px solid transparent;
}

.plant-edit-btn:hover {
	background: var(--neon-light);
	border-color: #9cd40029;
}

.review-card {
	display: flex;
	gap: 20px;
	align-items: flex-start;
	border-radius: 15px;
	background: #fff;
	box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.12);
	padding: 20px 20px 14px;
	margin: 20px 0;
	max-width: 700px;
}

.review-card .img-text {
	font-size: 40px;
	line-height: 40px;
	color: #fff;
}

.review-card > div:first-child {
	width: 10%;
	border-radius: 50%;
	background: maroon;
	aspect-ratio: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.rstats {
	display: flex;
	align-items: center;
	gap: 20px;
	margin: 8px 0 10px;
}

.rstats > div:first-child {
	background: var(--neon);
	padding: 3px 5px;
	display: flex;
	gap: 3px;
	border-radius: 5px;
	align-items: center;
}

.rstats > div:first-child span {
	font-size: 12px;
	color: white;
}

.ractions {
	display: flex;
	align-items: center;
	gap: 30px;
	margin-top: 10px;
}

.ractions > button {
	display: flex;
	align-items: center;
	gap: 8px;
	background: none;
}

#bphotos > div:first-child {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

@media screen and (max-width: 700px) {
	.profile-btns-container > * {
		min-width: 48%;
		width: fit-content;
	}
	#business_cover_images {
		--gap: 5;
		--center-w: 100%;
		--primary-w: 100%;
		--primary-h: calc(60% - ((var(--gap) / 2) * 1px));
		--center-img-w: calc(50% - ((var(--gap) / 2) * 1px));
		--center-img-h: calc(40% - ((var(--gap) / 2) * 1px));

		& > div {
			flex-direction: column;
			aspect-ratio: 0;
		}
		& > div > div#center-cover {
			flex-direction: row;
		}
		& > div > div#portrait-cover {
			display: none;
		}
	}
	.review-card .img-text {
		font-size: 24px;
		line-height: 24px;
	}
	#order-online {
		gap: 25px;
		flex-direction: column;
	}
	#order-online > div:first-child {
		flex-direction: row;
		overflow: auto;
		white-space: nowrap;
	}
	.review-card {
		gap: 16px;
		padding: 10px;
	}
	.ractions {
		gap: 16px;
	}
	.review-card > div:first-child {
		width: 14%;
	}
	.oprod-content {
		padding: 10px 15px;
	}
	#ptabs a > div {
		padding: 5px 10px;
	}
	.oprod-img {
		width: 40%;
		height: 40%;
	}
}

@media screen and (max-width: 500px) {
	#order-online > div:first-child button {
		padding: 0.6rem 0.8rem;
		width: fit-content;
		/* min-width: 16vw; */
		justify-content: center;
	}
	#business_cover_images > div > div#primary-cover {
		aspect-ratio: 16/9;
		height: auto;
		overflow: hidden;
	}
	#business_cover_images > div > div#center-cover {
		display: none !important;
	}
	#order-online h2 {
		margin-bottom: 20px;
	}
	#order-online > div:last-child > div {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: flex-start;
		gap: 10px;
	}
	.oprod {
		/* min-width: 215px; */
		flex-direction: column;
		display: inline-block;
		width: calc(50% - 10px);
		margin: 0;
	}
	.oprod-content {
		width: auto;
		padding: 1.5vw;
		/* span > b {} */
	}
	.oprod-img {
		width: 100%;
	}
	.oprod > div:last-child a {
		width: fit-content;
		margin-left: auto;
	}
	/* .profile-btns-container > * {
		min-width: 48%;
		width: fit-content;
	}
	#business_cover_images {
		--gap: 5;
		--center-w: 100%;
		--primary-w: 100%;
		--primary-h: calc(60% - ((var(--gap) / 2) * 1px));
		--center-img-w: calc(50% - ((var(--gap) / 2) * 1px));
		--center-img-h: calc(40% - ((var(--gap) / 2) * 1px));

		& > div {
			flex-direction: column;
			aspect-ratio: 0;
		}
		& > div > div#center-cover {
			flex-direction: row;
		}
		& > div > div#portrait-cover {
			display: none;
		}
	}
	.review-card .img-text {
		font-size: 24px;
		line-height: 24px;
	}
	#order-online {
		gap: 25px;
		flex-direction: column;
	}
	#order-online > div:first-child {
		flex-direction: row;
		overflow: auto;
		white-space: nowrap;
	}
	.review-card {
		gap: 16px;
		padding: 10px;
	}
	.ractions {
		gap: 16px;
	}
	.review-card > div:first-child {
		width: 14%;
	}
	.oprod-content {
		padding: 10px 15px;
	}
	#ptabs a > div {
		padding: 5px 10px;
	}
	.oprod-img {
		width: 40%;
		height: 40%;
	} */
}

@media screen and (max-width: 460px) {
	.oprod {
		width: 100%;
	}
	.oprod:nth-child(even) {
		margin: 0;
	}
	.oprod-content {
		padding-inline: 0.8rem;
	}
	.profile-btns-container {
		flex-wrap: wrap;
		& > * {
			width: 100%;
		}
	}
}
